import { Injectable, NgZone } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { UserService } from '../../services/users/user.service';
import { GetUserVerifyAction } from './user.actions';
import { Router } from '@angular/router';

export class UserStateModel {}

@State<UserStateModel>({
  name: 'user',
  defaults: {},
})
@Injectable()
export class UserState {
  constructor(
    private userService: UserService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  @Action(GetUserVerifyAction)
  postLogin(ctx: StateContext<UserStateModel>, action: GetUserVerifyAction) {
    return this.userService.getVerifyUser(action.payload).subscribe({
      next: (response) => {
        if (response) {
          this.ngZone.runOutsideAngular(() => {
            this.router.navigate(['auth/login']);
          });
        }
      },
      error: (error) => {
        this.ngZone.runOutsideAngular(() => {
          this.router.navigate(['auth/signup/personal-info']);
        });
      },
    });
  }
}
