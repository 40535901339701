import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../../utils/app-settings';
import {
  IVerifyResponse,
  IVerifyUserRequest,
} from '../../interfaces/user.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class UserService {
  constructor(
    private httpClient: HttpClient,
    private appSettings: AppSettings
  ) {}

  getVerifyUser(payload: IVerifyUserRequest): Observable<IVerifyResponse> {
    const text = payload.email ? 'email' : 'phone';
    const params = new HttpParams().set(
      `${text}`,
      payload.email ? payload.email : payload.phone
    );

    const url = this.appSettings.user.urls.getVerifyUser;

    return this.httpClient.get<IVerifyResponse>(url, { params });
  }
}
