import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { environment } from '../../../environments/environment';
import { AuthState } from './auth/auth.state';
import { UserState } from './user/user.state';

export const ngxsProviders: EnvironmentProviders = importProvidersFrom(
  NgxsModule.forRoot([AuthState, UserState], {
    developmentMode: !environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    keys: ['auth.createUser'],
  }),
  NgxsLoggerPluginModule.forRoot(),
  NgxsReduxDevtoolsPluginModule.forRoot()
);
