import { Injectable } from '@angular/core';
import packageJson from '../../../../package.json';
import { EndPoint } from './app-end-points';

@Injectable({
  providedIn: 'root',
})

export class AppSettings {
  public auth = {
    urls: {
      postLogin: EndPoint.urlBase('auth/login'),
      postSignup: EndPoint.urlBase('auth/signup'),
      postGoogleLogin: EndPoint.urlBase('auth/google/login'),
      postRequestVerify: EndPoint.urlBase('auth/request-verify'),
      postLogout: EndPoint.urlBase('auth/logout'),
      postRefresh: EndPoint.urlBase('auth/refresh-token'),
      postForgotPassword: EndPoint.urlBase('auth/forgot-password'),
      postVerifyForgotPassword: EndPoint.urlBase('auth/verify-forgot-password'),
      postVerifyOtp: EndPoint.urlBase('auth/verify-otp'),
    },
  };

  public user = {
    urls: {
      getVerifyUser: EndPoint.urlBase('users')
    }
  }

  public app = {
    name: 'Store Store',
    version: packageJson.version,
  };
}
